.back {
    margin:0em auto;
    font-family:"Roboto";
}
.back span {
    font-size:17px;
    color:#F2C640;
    background:#262B37;
    display:table-cell;
    box-shadow:inset 0 0 5px rgba(0,0,0,0.3), 0 5px 0 #ccc;
    padding: 0 10px;
    line-height: 40px;
    animation:jumb 1.5s infinite;
}
@keyframes jumb {
    0% {
        transform:translateY(0px)
    }
    50% {
        transform:translateY(-10px);
        box-shadow:0 10px 0 #F2C640;
    }
    100% {
        transform:translateY(0px)	
    }
}
.back span:nth-child(1) {
    animation-delay:0s;
}
.back span:nth-child(2) {
    animation-delay:.1s;	
}
.back span:nth-child(3) {
    animation-delay:.2s;
}
.back span:nth-child(4) {
    animation-delay:.3s;	
}
.back span:nth-child(5) {
    animation-delay:.4s;
}
.back span:nth-child(6) {
    animation-delay:.5s;	
}
.back span:nth-child(7) {
    animation-delay:.6s;
}