.vehicle_image {
    position: relative;
    margin: 10px 10px 10px 0;
}

.vehicle_image>input[type="file"] {
    display: none;
}

.vehicle_image label {
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-width: 1px;
    color: #454845;
    padding: 38px 26px;
    border: 1px dotted #9747ff;
    border-radius: 50px;
    display: table;
}

.vehicle_image p {
    color: #73008f;
}

.userlabel {
    font-weight: bold;
}