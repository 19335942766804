* {
    box-sizing: border-box;
  }
  
  
  .imagediv{
      display: flex;
      justify-content: center;
      margin-bottom: 4%;
  }
  .image {
      width: 25vw;
      height: 25vh;
      border-radius: 10px;
  }
  
  .strong{
      color: #478779;
  }
  
  p{
      padding: 0px 14px 0px 14px;
  }
  @media screen and (min-width: 200px) {
   .image{
    width: 31vw;
      height: 12vh;
    }
  }
  @media screen and (min-width: 300px) {
   .image{
    width: 32vw;
      height: 20vh;
    }
  }
  @media screen and (min-width: 480px) {
   .image{
    width: 25vw;
      height: 25vh;
    }
  }
  @media screen and (min-width: 860px) {
   .image{
    width: 20vw;
      height: 25vh;
    }
  }
  @media screen and (min-width: 1200px) {
   .image{
    width: 18vw;
      height: 25vh;
    }
  }