body {
    color: #444;
    font-family: "Open Sans", sans-serif;
  }
  
  h1 {
    font-family: "Roboto", sans-serif;
    font-size: calc(1.375rem + 1.5vw);
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 550;
    line-height: 1.2;
  }
  a {
    color: #02a664;
    text-decoration: none;
  }
  a:hover {
    color: #ffe100;
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 2.5rem;
    }
  }
  