.upload_img img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: left;
}

/* .heading {
  font-size: xx-large;
  font-weight: 600;
  color: #4f5d73;
  font-size: 1.25rem;

}
.common_layout {
  margin: 25px;
  justify-content: space-around;
}
.content-container {
  min-height: calc(100vh - 34px);
} */
.booking-address {
  height: 80px;
  display: block;
  overflow-y: auto;
}

.project-item {
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #26099f;
  padding: 149px 0;
  margin: 10px 0;
  border-radius: 10px;
}

.img_box a {
  width: 100%;
  display: table;
}

.view_prname {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(22, 5, 93, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.view_prname p {
  color: #fff;
  font-size: 18px;
}

.upload_img {
  position: relative;
}

.viewhide {
  display: none;
}

.img_box:hover .viewhide {
  display: block;
}

/* 
.user-info span {
  color: #000;
} */

/* .user-info a {
  color: 0645AD;
} */

.span_add_new {
  font-size: 20px;
  margin-top: 10px;
}

.img_box {
  background: #fff;
  border-radius: 10px;
  margin: 10px 0;
  box-shadow: 0px 1px 5px 0px #0000003d;
  min-height: 366px;
}

.img_txt br {
  display: none;
}

.img_txt {
  position: relative;
  padding: 15px;
}

.img_txt h3 {
  font-size: 20px;
  margin: 5px 0 0;
}

.img_txt span {
  font-size: 14px;
  padding-bottom: 11px;
  display: inline-block;
}

.rd_frop {
  position: absolute;
  top: 20px;
  background: transparent;
  right: 0;
}

.user-info li span {
  font-size: 14px;
  padding-bottom: 2px;
  margin-left: 9px;
}

.user-info li svg {
  color: #73a02e;
}

.star svg {
  color: #ffeb00 !important;
}

.user-info {
  border-top: 1px solid #ccc;
  padding: 10px 0 0 0;
}

.user-info li span {
  padding: 0;
  margin: 2px;
}

.vr_btns img {
  display: table;
  width: auto;
  margin: 0 auto 6px;
  text-align: center;
}

.vr_btns {
  border: 1px solid #ccc;
  border-radius: 8px;
  display: table;
  padding: 18px 0;
  color: #000;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

/* .img_prv img {
  width: 100%;
} */

@media only screen and (max-width: 767px) {
  .img_txt {
    position: relative;
    margin: 10px 0;
  }
}

.img_prev_game span {
  color: #f12222;
}

.img_prev_game span {
  position: absolute;
  top: -7px;
  right: -3px;
}

.img_prev_game {
  position: relative;
  width: 50px;
}

.img_prev_game img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

:root {
  --card-height: 300px;
  --card-width: calc(var(--card-height) / 1.5);
}

/* * {
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191c29;
} */
.card {
  width: var(--card-width);
  height: var(--card-height);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 36px;
  perspective: 2500px;
  margin: 0 50px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.card:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}

.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%);
}

.wrapper:hover {
  bottom: 0;
  opacity: 0.6;
  background-image: linear-gradient(to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%);
}

.card:hover .wrapper::before,
.wrapper::after {
  opacity: 0.5;
}

.card:hover .wrapper::after {
  height: 120px;
}

.title {
  width: 100%;
  transition: transform 0.5s;
}

.card:hover .title {
  transform: translate3d(0%, -50px, 100px);
}

.character {
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  top: 70px;
  z-index: -1;
}

.card:hover .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}

.chartButtonActive {
  color: blue;
  border-radius: 20px;

}

.chartButtonInActive {
  color: blue;
  border-radius: 20
}

/*  */



.smooth-div.open {
  /* max-height: 1000px; */
  opacity: 1;
  animation: slideDown 0.2s;


}

.smooth-div.close {
  /* max-height: 1000px; */
  opacity: 1;
  animation: slideUp 3s;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    opacity: 1;
    height: fit-content;
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    height: fit-content;
    /* opacity: 0; */
  }

  to {
    opacity: 0;
    height: 0;
  }
}