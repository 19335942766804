.refund-padding-left {
  padding-left: 40px !important;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}
.refund-padding-left strong {
  font-weight: bolder;
}
.privacy-padding-align {
  text-align: left;
  padding-left: 40px !important;
}
