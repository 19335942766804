@import url(https://fonts.googleapis.com/css?family=Raleway:700);



/* a {
  color: #EE4B5E !important;
  text-decoration:none;
} */
/* a:hover {
  color: #FFFFFF !important;
  text-decoration:none;
} */

.text-wrapper {
    height: 94vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: #2228cc;
   position: absolute;
}

.title {
    font-size: 5em;
    font-weight: 700;
    color: #EE4B5E;
}

.subtitle {
    font-size: 40px;
    font-weight: 700;
    color: #1FA9D6;
}
.isi {
    font-size: 18px;
    text-align: center;
    margin:30px;
    padding:20px;
    color: white;
}
