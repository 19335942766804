.profile-pic {
    position: relative;
    /* margin: 10px 10px 10px 0; */
    /* position: relative; */
    width: 125px;
    
  }
  .profile-pic > input[type="file"] {
    display: none;
    /* width: fit-content; */
    /* height: 1000px;   */
    /* max-height: 400px; */
  }
  .profile-pic label {
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-width: 1px;
    color: #454845;
    /* padding: 38px 26px; */
    border: 1px dotted #9747ff;
    border-radius: 50px;
    display: table;
    width: auto;

  }
  .profile-pic p {
    color: #73008f;
  }

.userlabel{
  font-weight: bold;
}  
  